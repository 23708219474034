import React, { useContext, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import getConfig from 'next/config';
import { useTranslation } from 'next-i18next';
import { AxiosResponse } from 'axios';
import { Toast } from 'primereact/toast';
import { LogsteoApiType, logsteoApiGenerator } from './logsteo-api.v2';
import { useRouter } from 'next/router';
import { ToastContext } from '@components/ApplicationContext/ToastContext';
import { isNotNullOrUndefined } from '@utils/utils';

const resolveQueryParams = (params: any[]) => {
  const retObject = {};
  params.forEach((item) => {
    Object.keys(item).forEach((key) => {
      retObject[key] = item[key];
    });
  });

  return retObject;
};

interface Error {
  message: string;
  name: string;
  stack: string;
  config: {
    url: string;
    method: string;
    data: string;
    headers: { [key: string]: string };
  };
}

interface Callbacks {
  onError?: (error: Error) => void;
  onConflict?: (conflictInfo: any) => void;
}

interface ApiContextType extends LogsteoApiType {}

export const ApiContext = React.createContext<ApiContextType>(undefined);

export const Provider: React.FC = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();
  const { publicRuntimeConfig } = getConfig();
  const frontendservicepublicendpoint = publicRuntimeConfig.FRONTENDSERVICE_PUBLIC_ENDPOINT;
  const emailtemplateservicepublicendpoint = publicRuntimeConfig.EMAILTEMPLATESERVICE_PUBLIC_ENDPOINT;
  const calendarServicePublicEndpoint = publicRuntimeConfig.CALENDARSERVICE_PUBLIC_ENDPOINT;
  const frontendbehost = publicRuntimeConfig.FRONTEND_BE_HOST;
  const { i18n } = useTranslation('common');
  const toastRef = useRef(null);
  const [loading, setLoading] = useState([]);
  const { t } = useTranslation();
  const { showToastMessage } = useContext(ToastContext);

  const router = useRouter();

  const handleError = (methodName: String, error: Error, payload?: any) => {
    console.log(`error: ${JSON.stringify(error)}`);
    showToastMessage(
      t(`api.errorApi`, `Nastala chyba při komunikaci s API`),
      <>
        <div className="toast-wrapper">
          <div>{error.message}</div>
          <div>
            <strong>{methodName}</strong> - {error?.config?.method} - {error?.config?.url}
          </div>
          {Object.keys(payload).length > 0 && (
            <>
              <div>Payload</div>
              <div>
                <pre className="long-error-pre">{JSON.stringify(payload, null, 2)}</pre>
              </div>
            </>
          )}
        </div>
      </>,
      'error',
      30000,
    );
    /*toastRef.current.show([
      {
        severity: 'error',
        life: 15000,
        sticky: true,
        closable: true,
        content: (
          <div className="toast-wrapper">
            <h3>{t(`api.errorApi`, `Nastala chyba při komunikaci s API`)}</h3>
            <div>{error.message}</div>
            <div>
              {error.config.method} - {error.config.url}
            </div>
            <div>Payload </div>
            <div>
              <pre className="long-error-pre">{JSON.stringify(payload, null, 2)}</pre>
            </div>
            <pre>{error.stack}</pre>
          </div>
        ),
      },
    ]);*/
  };

  const isSuccess = (response: AxiosResponse) => {
    return response.status >= 200 && response.status < 300 && response.data.errorType === null;
  };

  const isConflict = (response: AxiosResponse) => {
    if (response.status >= 200 && response.status < 300 && response.data.errorType === 'CONFLICT') {
      return true;
    }
    return false;
  };

  const logsteoApiv2 = logsteoApiGenerator(
    frontendbehost + frontendservicepublicendpoint,
    i18n,
    handleError,
    getIdTokenClaims,
    router,
  );

  return (
    <ApiContext.Provider value={{ ...logsteoApiv2 }}>
      <>{children}</>
    </ApiContext.Provider>
  );
};
