import React, { useContext, useEffect, useRef } from 'react';
import { ApiContext } from '../../api/api';
import { useAuth0 } from '@auth0/auth0-react';
import { useImmer } from 'use-immer';
import { useRouter } from 'next/router';
import { isNullOrUndefined } from '@utils/utils';
import { Draft } from 'immer';
import { Toast } from 'primereact/toast';

interface ToastContextType {
  showToastMessage: (summary: string, detail: any, severity: string, life?: number) => void;
  redirectWithToastMessage: (url: string, summary: string, detail: string, severity: string) => void;
}

export const ToastContext = React.createContext<ToastContextType>(undefined);

export const ToastProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const toast = useRef();
  const router = useRouter();

  const showToastMessage = (summary: string, detail: string, severity: string, life = 5000) => {
    if (toast && toast.current) {
      //@ts-ignore
      toast.current.show({ severity, summary, detail, closable: true, life, stacked: true });
    }
  };

  useEffect(() => {
    const toastMessage = router.query['summary'] as string;

    if (toastMessage) {
      const summary = router['query']['summary'] as string;
      const detail = router['query']['detail'] as string;
      const severity = router['query']['severity'] as string;

      if (toast && toast.current) {
        //@ts-ignore
        toast.current.show({ severity, summary, detail });
      }
    }
  }, [router['query']['summary']]);

  const redirectWithToastMessage = (url: string, summary: string, detail: string, severity: string) => {
    router.push(appendQueryParams(url, summary, detail, severity), appendQueryParams(url, summary, detail, severity), {
      shallow: false,
    });
  };

  const appendQueryParams = (url: string, summary: string, detail: string, severity: string): string => {
    return `${url}?summary=${summary}&detail=${detail}&severity=${severity}`;
  };

  return (
    <ToastContext.Provider value={{ showToastMessage, redirectWithToastMessage }}>
      {children}
      <Toast ref={toast} style={{ zIndex: 40000 }} />
    </ToastContext.Provider>
  );
};
